import React, { useEffect, useRef } from 'react';
import './services.css';
import img from '../Images/abstract-background-with-low-poly-design.jpg';
import { useInView } from 'react-intersection-observer';
import ecom from '../Images/ecom (2).jpg';
import smallbusiness from '../Images/small-business01.jpg';
import responsive from '../Images/respo.jpg';
import cms from '../Images/cms (2).jpg';
import portfolio from '../Images/Port.web.jpg';
import SEOImage from '../Images/Search engine.JPG';

const Service = ({ title, description, image, isEven }) => {
  const [ref, inView] = useInView(); // Hook to detect if the service is in view

  return (
    <div className={`service ${inView ? 'visible' : ''}`} ref={ref}>
      <div className={`service-image ${isEven ? 'align-left' : 'align-right'}`}>
        <img src={image} alt="Service" />
      </div>
      <div className="service-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Services = () => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animated');
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const targets = ref.current.querySelectorAll('.service');
    targets.forEach((target) => {
      observer.observe(target);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="container" ref={ref}>
      <div className="image-container">
        <img src={img} alt="Company Logo" className="serviceimg" />
        <div className="image-overlay">
          <h1 className="quote">Discover the Power of Zirtech </h1>
          <p>Transforming Businesses Digitally</p>
        </div>
      </div>
      <h2 className="Our-service">Our Services</h2>
      <Service
        title="Small Business Websites"
        description="We recognize the need for professional, user-friendly websites for small businesses. Our team collaborates closely with you to craft a custom site that reflects your brand, engages your audience, and drives results. Whether e-commerce or informational, we create websites tailored to your needs."
        image={smallbusiness}
        isEven={false}
      />
      <Service
        title="Portfolio Websites"
        description="As a creative professional, your portfolio website serves as your online resume and showcase. We specialize in creating stunning portfolios that highlight your work, skills, and achievements. Whether you're a photographer, artist, or any other creative, we'll design a visually striking website to showcase your talent and help you stand out."
        image={portfolio}
        isEven={true}
      />
      <Service
        title="Responsive Design"
        description="In today's digital age, it's essential for websites to be accessible across various devices and screen sizes. That's why all of our websites are built using responsive design techniques, ensuring an optimal viewing experience for your visitors, whether they're browsing on a desktop, laptop, tablet, or smartphone."
        image={responsive}
        isEven={false}
      />
      <Service
        title="SEO Optimisation"
        description="Having a visually appealing website is only half the battle; it also needs to be easily discoverable by search engines. Our team will optimize your website for search engines, implementing industry best practices to improve your site's visibility and rankings on search engine results pages (SERPs), driving organic traffic to your website."
        image={SEOImage}
        isEven={true}
      />
      <Service
        title="Content Management Systems (CMS)"
        description="We understand the importance of having control over your website's content. That's why we offer integration with popular content management systems like WordPress, Joomla, or custom-built CMS solutions. With an easy-to-use CMS, you can effortlessly update and manage your website's content without any technical expertise."
        image={cms}
        isEven={false}
      />
      <Service
        title="E-commerce Solutions"
        description="If you're looking to sell products or services online, we've got you covered. Our team can integrate robust e-commerce solutions into your website, allowing you to showcase your products, accept payments securely, manage inventory, and provide a seamless shopping experience for your customers."
        image={ecom}
        isEven={true}
      />
    </div>
  );
};

export default Services;
