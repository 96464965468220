import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../Logos/ZirTech Tertiary Logo (White).png";
import { IoCloseSharp } from "react-icons/io5";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const closeMenu = () => {
    setIsActive(false);
  };

  return (
    <div className={`navbar-container${scrolling ? " scrolling" : ""}`}>
      <nav className={isActive ? "active nav-container" : "nav-container"}>
        <div className="navbar-logo-container">
          <Link to="/" className="logo">
            <img src={logo} alt="company-logo" className="navbar-logo-image" />
          </Link>
        </div>
        <div className="menu-icons" onClick={toggleMenu}>
          {isActive ? <IoCloseSharp /> : <GiHamburgerMenu />}
        </div>
        <div className="navbar-menu-container">
          <ul className="nav-list">
            <li className="nav-list-item" onClick={closeMenu}>
              <Link to="/" className="nav-link-item">
                Home
              </Link>
            </li>
            <li className="nav-list-item" onClick={closeMenu}>
              <Link to="/services" className="nav-link-item">
                Services
              </Link>
            </li>
            <li className="nav-list-item" onClick={closeMenu}>
              <Link to="/about-us" className="nav-link-item">
                About Us
              </Link>
            </li>
            <li className="nav-list-item" onClick={closeMenu}>
              <Link to="/contactUs" className="nav-link-item">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
