// import React, { useEffect, useState } from "react";
// import { Route, Routes, useLocation } from "react-router-dom";
// import AboutUs from "./Components/AboutUs/AboutUs";
// import HomePage from "./Components/HomePage/Home";
// import Navbar from "./Components/Header/Navbar";
// import Contact from "./Components/ContactUs/Contact";
// import Footer from "./Components/Footer/Footer";
// import Services from "./Components/services/services";
// import Chat from "./Components/chatbot/chat";

// function App() {
  
//   return (
//     <>
//       <Navbar />
//       <Chat/>
//       <Routes>
//         <Route path="/about-us" element={<AboutUs />} />
//         <Route exact path="/" element={<HomePage />} />
//         <Route exact path="/services" element={<Services />} />
//         <Route path="/contactUs" element={<Contact />} />
//       </Routes>
     
//       <Footer />
//     </>
//   );
// }

// export default App;
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./Components/AboutUs/AboutUs";
import HomePage from "./Components/HomePage/Home";
import Navbar from "./Components/Header/Navbar";
import Contact from "./Components/ContactUs/Contact";
import Footer from "./Components/Footer/Footer";
import Services from "./Components/services/services";
import Chat from "./Components/chatbot/chat";
import "./App.css"; // Import your main CSS file
import Loader from "./Components/Loader/loader";

function App() {
  const [loading, setLoading] = useState(true); // State to track loading status

  // Simulate loading effect using useEffect hook
  useEffect(() => {
    // Simulate loading for 3 seconds
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 3 seconds
    }, 3000);

    // Clean up timer
    return () => clearTimeout(timer);
  }, []); // Run effect only once on component mount

  return (
    <>
      {/* Conditionally render the Loader component based on the loading state */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <Chat />
          <Routes>
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contactUs" element={<Contact />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
