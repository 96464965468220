import React,{ useRef }  from "react";
import "./Contact.css";

import emailjs from '@emailjs/browser';
import backgroundImage from "../Images/contactus.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

  emailjs
    .sendForm('service_hlebebl', 'template_9deqckf', form.current, {
      publicKey: 'MNzpQlYcRLOMd-CxW',
    })
    .then(
      () => {
        alert('Thank you for your response! Our team will reach out to you soon');
         // Reset form fields
         form.current.reset();
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
    }
  
  return (
    <>
      <section
        className="hero"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="hero-content">
            {/* <h1 className="hero-title">Contact Us</h1>
            <p className="hero-description">Get in touch with us</p> */}
            <br />
          </div>
        </div>
      </section>
      <section className="second-section">
        <div className="main-container">
          <div className="left-container">
            <div className="card-1">
              <div className="circle-icon">
                <PhoneIcon />
              </div>

              <div className="icon-box">
    
                <p>+91-6005730747</p>
              </div>
            </div>

            <div className="card-1">
              <div className="circle-icon">
                <EmailIcon />
              </div>

              <div className="icon-box">
              
                <p>info@zirtechsoftware.solutions</p>
              </div>
            </div>
            <div className="card-1">
              <div className="circle-icon">
                <InstagramIcon />
              </div>
              <div className="icon-box">
              
                <p>@zirtechsoftwaresolutions</p>
              </div>
            </div>
            <div className="card-1">
              <div className="circle-icon">
                <XIcon />
              </div>
              <div className="icon-box">
              
                <p>@zirtechSS</p>
              </div>
            </div>
            <div className="card-1">
              <div className="circle-icon">
                <FacebookIcon />
              </div>
              <div className="icon-box">
              
                <p>@zirtechsoftwaresolutions</p>
              </div>
            </div>
          </div>

          <div className="right-container">
          <form  ref={form} onSubmit={sendEmail}>
                          <div className="form-div">
                <h3 className="mt-2">Send Us A Message</h3>
<div className="form-row"> <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input type="text" id="name" name="name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone:</label>
                  <input type="number" id="phone" name="phone" required />
                </div></div>
               
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject:</label>
                  <input type="text1" id="subject" name="subject" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message:</label>
                  <textarea id="message" name="message" required></textarea>
                </div>
                <div className="form-group">
                  <button type="submit" value="Send">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
// import React from "react";
// import "./Contact.css";
// import backgroundImage from "../Images/handshake.webp";
// import PhoneIcon from "@mui/icons-material/Phone";
// import EmailIcon from "@mui/icons-material/Email";
// import InstagramIcon from '@mui/icons-material/Instagram';
// import XIcon from '@mui/icons-material/X';

// const Contact = () => {
//   return (
//     <>
//       <section
//         className="hero"
//         style={{ backgroundImage: `url(${backgroundImage})` }}
//       >
//         <div className="container">
//           <div className="hero-content">
//             <h1 className="hero-title">Contact Us</h1>
//             <p className="hero-description">Get in touch with us</p>
//           </div>
//         </div>
//       </section>
//       <section className="second-section">
//         <div className="main-container">
//           <div className="left-container">
//             <div className="card-1">
//               <div className="circle-icon">
//                 <PhoneIcon />
//               </div>

//               <div className="icon-box">
//                 <h5>Phone Number</h5>
//                 <p>123-456-7890</p>
//               </div>
//             </div>

//             <div className="card-1">
//               <div className="circle-icon">
//                 <EmailIcon />
//               </div>

//               <div className="icon-box">
//                 <h5>Email</h5>
//                 <p>example@example.com</p>
//               </div>
//             </div>
//             <div className="card-1">
//               <div className="circle-icon">
//                 <InstagramIcon />
//               </div>
//               <div className="icon-box">
//                 <h5>Instagram</h5>
//                 <p>zirtech001</p>
//               </div>
//             </div>
//             <div className="card-1">
//               <div className="circle-icon">
//                 <XIcon />
//               </div>
//               <div className="icon-box">
//                 <h5>Twitter</h5>
//                 <p>@ZirTech_sol001</p>
//               </div>
//             </div>
//           </div>

//           <div className="right-container">
//             <form>
//               <div className="form-div">
//                 <h3 className="mt-2">Send us a message</h3>
//                 <div className="form-group">
//                   <label htmlFor="name">Name:</label>
//                   <input type="text" id="name" name="name" required />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="phone">Phone:</label>
//                   <input type="number" id="phone" name="phone" required />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="email">Email:</label>
//                   <input type="email" id="email" name="email" required />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="subject">Subject:</label>
//                   <input type="text1" id="subject" name="subject" required />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="message">Message:</label>
//                   <textarea id="message" name="message" required></textarea>
//                 </div>
//                 <div className="form-group">
//                   <button type="submit">Submit</button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Contact;
