import React, { useEffect } from "react";
// import Footer from "../Footer/Footer.js";
import { Carousel } from "react-bootstrap";
import Player from "../Images/video1.mp4";
import { IoHome } from "react-icons/io5";
import { IoPower } from "react-icons/io5";
import { AiOutlineGlobal } from "react-icons/ai";
import portfolio from "../Images/PORTFOLIO.jpg";
import website from "../Images/smallbusiness.jpg";
import ecommerce from "../Images/ecom.jpg";
import feature1 from "../Images/feature1.jpg";
import feature2 from "../Images/feature2.jpg";
import feature3 from "../Images/feature3.jpg";
import banner1 from "../Images/banner4.jpg";
import banner2 from "../Images/banner2.jpg";
import banner3 from "../Images/banner3.jpg";
import branding from "../Images/branding.png";
import banner5 from "../Images/banner5.jpg";
// import image from "../Images/image.png";
 import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

export default function HomePage() {
  // const [state, updateState] = useState("EnterpriseSystems");
  useEffect(() => {
    window.addEventListener("scroll", HomeHandleScroll);
    return () => {
      window.removeEventListener("scroll", HomeHandleScroll);
    };
  });

  const HomeHandleScroll = () => {
    var revals = document.querySelectorAll(".HomeContentContainer");
    for (var i = 0; i < revals.length; i++) {
      var windowheight = window.innerHeight;
      var revaltop = revals[i].getBoundingClientRect().top;
      var revalpoint = 150;
      if (revaltop < windowheight - revalpoint) {
        revals[i].classList.add("active");
      } else {
        revals[i].classList.remove("active");
      }
    }
  };

  // const settings = {
  //   dots: false,
  //   prevArrow: <BsArrowLeft size="30" color="black" />, // Replace CustomPrevArrow and CustomNextArrow with your own arrow components
  //   nextArrow: <BsArrowRight size="30" color="black" />,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  // };
  // const images = [image1, image2, image3, image];


  const Banners=[
    {
      projectId:1,
      imageUrl:banner1,
      title:"Operate",
      description:"Master Your Operations with Expert Managed Services"
    },
    {
      projectId:2,
      imageUrl:banner2,
      title:"BUILD YOUR DIGITAL CORE",
      description:"Empower Your Enterprise with Unrivalled Systems"
    },
    {
      projectId:3,
      imageUrl:banner3,
      title:"Embrace the AI Transformation",
      description:"Unleashing New Business Horizons"
    },
    {
      projectId:4,
      imageUrl:banner5,
      title:"Innovate",
      description:"Harness Cutting-Edge Frameworks for Business Innovation"
    },
  ]

  const cardsData = [
    {
      id: 1,
      icon: 'https://27058982.fs1.hubspotusercontent-eu1.net/hubfs/27058982/ACP/Untitled%20design%20(20).jpg',
      image: portfolio,
      description: 'Craft a captivating portfolio that showcases your unique talents and achievements, driving client engagement and opening doors to new opportunities in your field.'
    },
    {
      id: 2,
      icon: 'https://27058982.fs1.hubspotusercontent-eu1.net/hubfs/27058982/ACP/Untitled%20design%20(20).jpg',
      image: website,
      description: 'Empower your small business with a custom-built website that reflects your brand identity, attracts new customers, and streamlines your online presence for growth and success.'
    },
    {
      id: 3,
      icon: 'https://27058982.fs1.hubspotusercontent-eu1.net/hubfs/27058982/ACP/Untitled%20design%20(20).jpg',
      image: ecommerce,
      description: 'Revolutionize your online presence with our cutting-edge e-commerce website development solutions, designed to drive sustainable growth in a rapidly evolving marketplace.'
    },
    {
      id: 4,
      icon: 'https://27058982.fs1.hubspotusercontent-eu1.net/hubfs/27058982/ACP/Untitled%20design%20(20).jpg',
      image: branding,
      description: "Transform your brand identity and captivate your audience with our innovative branding solutions, meticulously crafted to elevate your presence and drive lasting impact in today's dynamic market."
    },
  ];
  
  const HomeCards = [
    {
      projectId: 1,
      title: "Portfolio Development",
      imageUrl:feature2,
      description:
        "Craft visually stunning portfolios that captivate your audience and showcase your work effectively, helping your small business stand out in the digital landscape.",
    },
    {
      projectId: 2,
      title: "Website Development",
      description:
        "Empower your small business with a professionally designed website tailored to your unique needs. From sleek and modern designs to intuitive user experiences, we bring your brand to life online.",
      imageUrl:feature3,   
     },
    {
      projectId: 3,
      title: "E-commerce Solutions",
      description:
        "Take your small business online with our e-commerce solutions. From setting up secure payment gateways to designing user-friendly interfaces, we help you maximize your digital sales potential.",
      imageUrl:
      feature1,
    },
];


 


  return (
    <div className="HomePageTotalContainer">
     <div className="container-fluid p-0">
  <div className="row">
    <div className="col-12">
    <Carousel prevIcon={<BsArrowLeft size="20" color="white" />} nextIcon={<BsArrowRight size="20" color="white" />}>  
          {Banners.map((each, index) => (
          <Carousel.Item key={index}>
            <img
              style={{ height: "auto", maxWidth: "100%", maxHeight: "80vh", objectFit: "cover" }}
              className="d-block w-100 carouselBanner"
              src={each.imageUrl}
              alt={each.title}
            />
            <div className="homepage-carousel-caption-overlay">
              <h1
                style={{
                  color: "#ffffff",
                  fontWeight: "bolder",
                  fontSize: "4vw", // Adjust font size for responsiveness
                  textAlign: "center", // Center align text
                }}
              >
                {each.title}
              </h1>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "2vw", // Adjust font size for responsiveness
                  fontWeight: "400",
                  textAlign: "center", // Center align text
                }}
              >
                {each.description}
              </p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  </div>
</div>

      <h1 className="what-we-do">What We Do</h1>
      <div className="container mt-3">
      <div className="card-container">
       
        {cardsData.map(card => (
          <div className="card" key={card.id}>
            <img className="icon" src={card.icon} alt={`Icon ${card.id}`} />
            <img className="image" src={card.image} alt={`Card ${card.id}`} />
                        <div className="description">{card.description}</div>
          </div>
        ))}
      </div>
    </div>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12 mt-5 w-100">
            <div className="HomePageVideoContainer">
              <video
                id="plmbackground-video"
                className="HomePagevideoElement"
                autoPlay
                loop
                muted
                playsInline 
              >
                <source src={Player} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-0 mt-3">
        <div className="row">
          <div className="col-12 text-center mb-2">
           
          </div>
         
          <div
            className="col-12"
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
           
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row tex-center">
          <div className="col-12 mb-3 mt-3">
            <h1 className="text-center mb-5">ZirTech At A Glance</h1>
          </div>
          <div className="col-12 col-md-4 text-center mb-2">
            <div className="HomeContentContainer d-flex text-center justify-content-center">
              <IoHome size="60" color="rgba(15, 68, 148, 1.0)" />
              <div>
    <h4>2024 Inception</h4>
    <p style={{ width: "250px" }}>
        Founded in 2024 with a vision for pioneering modern solutions and redefining industry standards.
    </p>
</div>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center mb-2">
            <div className="HomeContentContainer d-flex text-center  justify-content-center">
              <IoPower size="60" color="rgba(15, 68, 148, 1.0)" />
              <div>
    <h4>Talent Network</h4>
    <p style={{ width: "250px" }}>
        Leveraging a network of delivery centers and a dynamic talent pool of 3500 individuals nurtured through strategic alliances.
    </p>
</div>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center mb-2">
            <div className="HomeContentContainer d-flex text-center   justify-content-center">
              <AiOutlineGlobal size="60" color="rgba(15, 68, 148, 1.0)" />
              <div>
    <h4>Global Presence</h4>
    <p style={{ width: "250px" }}>
    An extensive global network reflects our robust remote operation and widespread reach.
    </p>
</div>
            </div>
          </div>
        
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center mb-5 mt-2">Featured Solutions</h1>
          </div>
          {HomeCards.map((each) => (
            <div key={each.projectId} className="col-12 col-md-4  mb-3">
              <div className="HomecardsContainer text-center  shadow HomeContentContainer mb-2 p-2" style={{ height: "100%" }}>
                <img
                  src={each.imageUrl}
                  style={{ height: "200px", borderRadius: "8px", width: "90%" }}
                  alt={each.projectId}
                />
                <h3 className="mt-3 mb-2 text-center">{each.title}</h3>
                <p className="mt-3 mb-2 text-center">{each.description}</p>
                <div style={{ textAlign: "center" }}>
                
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
