import React, { useEffect } from "react";

const chatbotApi = "https://embed.tawk.to/65fdd08e1ec1082f04da3ac4/1hpjndbp6";

const Chat = () => {
  useEffect(() => {
    // var Tawk_API = Tawk_API || {};
    // var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = chatbotApi;
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []); // Empty dependency array to ensure this effect runs only once

  return <div></div>; // Empty div or any other JSX you want to render
};

export default Chat;

// <!--Start of Tawk.to Script-->
// <script type="text/javascript">
// var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/65fdd08e1ec1082f04da3ac4/1hpjndbp6';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
// </script>
// <!--End of Tawk.to Script-->