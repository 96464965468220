import React from 'react';
import LoaderImage from '../Images/loader.png';
import './Loader.css'; // Import CSS file for styling

const Loader = () => {
  return (
    <div className='loader-container'>
      <img src={LoaderImage} alt="loader" className="loader-image" />
    </div>
  );
}

export default Loader;
