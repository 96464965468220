import React from "react";
import "./AboutUs.css";
import aboutus from "../Images/About us 01.jpg";
// import ourvision from "../Images/Our Vision.JPG";
import member2 from "../Images/UmarZ.jpeg";
import member1 from "../Images/AsraarZ.jpeg";
import member4 from "../Images/AishaZ.jpeg";
import member3 from "../Images/UsmanZ.jpeg";
import member5 from "../Images/BurhanZ.jpeg";
import member6 from "../Images/AreebZ.jpeg";



const AboutUs = () => {
  return (
    
    <div className="aboutus-main-container">
      <div className="aboutus-first-container">
        <div className="aboutus-background"></div>
        <div className="aboutus-first-text-container">
          <h1 className="aboutus-heading">At ZirTech, we're not just embracing the future; we're coding it.</h1>
        </div>
      </div>

      <div className="aboutus-second-text-container">
  <div className="aboutus-content">
    <div className="aboutus-text">
      <h2 className="aboutus-second-heading">About Company</h2>
      <p className="aboutus-second-description">
        ZirTech is driven by the profound belief in technology's transformative potential to reshape industries and drive business success. With a dedicated team of experts, we specialize in delivering cutting-edge solutions tailored to address your unique challenges.
      </p>
      <p className="aboutus-second-description">
        Our commitment extends beyond mere problem-solving; we aim to foster long-term partnerships with our clients, empowering them to stay ahead in an ever-evolving landscape.
      </p>
    </div>
    <div className="aboutus-image">
      <img src={aboutus} alt="Company Logo" className="serviceimg" />
    </div>
  </div>
</div>



     

      <div className="company-unique-factor">
        <h2>Company Unique Factor</h2>
        <div className="cards-container">
          <div className="cardA">
          <i class="fas fa-rocket"></i>
  <span>Continous Innovation</span>
          </div>
          <div className="cardA"> <i class="fas fa-lightbulb"></i>
  <span>Deep Industry Insights</span></div>
          <div className="cardA"> <i class="fas fa-handshake"></i>
  <span>Long-term Partnerships</span></div>
          <div className="cardA">  <i class="fas fa-chart-line"></i>
  <span>Sustainable Growth</span></div>
        </div>
      </div>

      <div className="meet-our-team">
        <h2>Meet our team</h2>
        <p>Our team is a diverse group of talented individuals with expertise in various fields including technology, design, marketing, and business development. We are passionate about leveraging our skills and knowledge to drive innovation and deliver exceptional results for our clients. With a strong commitment to collaboration and continuous learning, we work together to overcome challenges and achieve our goals. Meet our team below:</p>

        <div className="team-members">
          <div className="team-member">
            <img src={member1} alt="Team Member 1" />
            <p>Asraar Javeed</p>
            <p>Software Developer</p>
            <a href="https://www.linkedin.com/in/asraar-javeed-021abd/"  target="_blank" rel="noopener noreferrer">
  <i class="fab fa-linkedin"></i>
</a>
          </div>
          <div className="team-member">
            <img src={member2} alt="Team Member 1" />
            <p>Umar Mohammad Sheikh</p>
            <p>Software Developer</p>
            <a href="https://www.linkedin.com/in/umar-mohammad-sheikh-7a4952190/"  target="_blank" rel="noopener noreferrer">
  <i class="fab fa-linkedin"></i>
</a>
          </div>
          <div className="team-member">
            <img src={member3} alt="Team Member 1" />
            <p>Mohammad Usman Shah</p>
            <p>Software Developer</p>
            <a href="https://www.linkedin.com/in/usman-shah-728098282/"  target="_blank" rel="noopener noreferrer">
  <i className="fab fa-linkedin"></i>
</a>
          </div>
          <div className="team-member">
            <img src={member4} alt="Team Member 1" />
            <p>Aaieshah Viqar</p>
            <p>Graphic Designer</p>
            <a href="https://www.linkedin.com/in/aaieshah-viqar-969153217/"  target="_blank" rel="noopener noreferrer">
  <i className="fab fa-linkedin"></i>
</a>
          </div>
          <div className="team-member">
            <img src={member5} alt="Team Member 1" />
            <p>Burhan Imtiyaz Dar</p>
            <p>Marketing Associate</p>
            <a href="https://www.linkedin.com/in/burhan-dar-546724134/"  target="_blank" rel="noopener noreferrer">
  <i className="fab fa-linkedin"></i>
</a>
          </div>
          <div className="team-member">
            <img src={member6} alt="Team Member 1" />
            <p>Areeb Aijaz Wani</p>
            <p>Marketing Associate</p>
            <a href="https://www.linkedin.com/in/areebwani/"  target="_blank" rel="noopener noreferrer">
  <i className="fab fa-linkedin"></i>
</a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AboutUs;
